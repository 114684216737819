// Theme Variables
$color-primary: #1a2048;
$color-secondary: #213e6f;
$color-tertiary: #008fc4;
$color-cta: $color-primary;
$color-danger: #c2272d;

$color-black: #373737;
$color-grey--dark: #454545;
$color-grey: #7D7D7D;
$color-grey--light: #ECECEC;
$color-grey--xlight: #F6F6F6;
$color-white: #FFFFFF;

$color-sku: #a2a2a2;

// Typography
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap');

$font-family--heading: "Roboto", serif;
$font-family--body: "Roboto", serif;

// Checkout
$checkout-background-color: $color-grey--xlight;
$amblog-featured-background-color: $color-grey--xlight; // TODO: Investigate why variable doesn't overwrite parent theme variable
$compare-remove-icon-color: $color-black;
$checkout-note-color: $color-white;

// Header
$header-background-colour: $color-white;
$header-icon-links-color: $color-primary;
$icon-links-cart-border-color: 1px solid $color-grey;

// Mini Cart
$minicart-counter-text-color: $color-white;

// Navigation
$menu-highlight-background-color: $color-danger;
$menu-highlight-text-color: $color-white;
$menu-highlight-hover-background-color: $color-white;
$menu-highlight-hover-text-color: $color-danger;

// Footer
$header-icon-links-color: $color-white;

// Heading
$heading-after: $color-tertiary;
